<template>
  <div>
    <a href="#" @click.prevent="openLog">
      <font-awesome-icon icon="eye" />
    </a>
    <b-modal
      size="lg"
      title="Logi"
      scrollable
      :hide-footer="true"
      v-if="showLogModal"
      v-model="showLogModal"
    >
      <loader v-if="isLoading" />
      <b-table-simple bordered small responsive="" v-else>
        <b-tr variant="primary">
          <b-th width="30%" class="text-center">Kellaaeg</b-th>
          <b-th class="text-center">Info</b-th>
        </b-tr>
        <b-tr v-for="(entry, entryKey) in logEntries" :key="entryKey">
          <b-td class="text-center">
            {{ entry.date | moment("DD.MM.YYYY HH:mm:ss") }}
          </b-td>
          <b-td class="text-left small">
            {{ entry.data }}
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: {
    log: {
      type: Object
    }
  },
  data() {
    return {
      showLogModal: false,
      isLoading: false,
      logEntries: []
    }
  },
  methods: {
    openLog() {
      this.isLoading = true;
      this.showLogModal = true;
      this.logEntries = this.log.log
      this.isLoading = false;
    },
  },
}
</script>
