<template>
  <div class="position-relative">
    <h1>
      Andmebaasi otsing
    </h1>

    <b-tabs content-class="p-3">
      <b-tab title="Otsing" active>
        <CodeSearch />
      </b-tab>
      <b-tab title="Tooted tarnija järgi">
        <StocksSearch />
      </b-tab>
      <b-tab title="Enim ostetud tooted" lazy>
        <TopOrdered />
      </b-tab>
      <b-tab title="Enim vaadatud tooted" lazy>
        <Top type="viewed" />
      </b-tab>
      <b-tab title="Avalehe tooted" lazy>
        <Featured />
      </b-tab>
      <b-tab title="Mitteaktiivsed tooted" lazy>
        <InActiveProducts />
      </b-tab>
      <b-tab title="Arhiveeritud tooted" lazy>
        <ArchivedProducts />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Top from "./search/Top";
import TopOrdered from "./search/TopOrdered";
import Featured from "./search/Featured";
import CodeSearch from "./search/CodeSearch";
import StocksSearch from "./search/StocksSearch";
import InActiveProducts from "./search/InActiveProducts";
import ArchivedProducts from "./search/ArchivedProducts";
import { mapGetters } from "vuex";

export default {
  components: {
    CodeSearch,
    Top,
    Featured,
    StocksSearch,
    TopOrdered,
    InActiveProducts,
    ArchivedProducts
  },
  computed: {
    ...mapGetters({
    }),
  },
};
</script>
