<template>
  <div class="p-2">
    <b-overlay
      :show="isMapInfoLoading"
      opacity="0.3"
      variant="white"
      rounded="sm"
    >
      <div class="mt-3 mb-2">
        <strong>"{{ map.supplier_category }}"</strong>
      </div>

      <div class="mb-3">
        <b-form-group class="mb-1">
          <b-form-checkbox
            v-model="map.ignore_on_import"
            switch
            @change="setCategoryMapIgnore"
          >
            Ignoreeri seda kategooriat importimisel
          </b-form-checkbox>
        </b-form-group>
      </div>

      <div class="mb-3" v-if="false">
        <strong>
          Kategooria hinnastus
          <b-badge v-if="map.deliveryPrice.length">{{ map.deliveryPrice[0].price }}</b-badge>
        </strong>
        <b-form class="mt-2" inline @submit.prevent="setCategoryDeliveryPrice">
          <b-form-input
            type="number"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Hind"
            size="sm"
            v-model.number="deliveryPrice"
          ></b-form-input>

          <b-button type="submit" size="sm" variant="primary">Salvesta</b-button>
        </b-form>
      </div>

      <b-button class="float-right" variant="outline-primary" size="sm" v-b-modal.category-modal>
        Vali kategooria puust
      </b-button>
      <div class="clearfix"></div>
      <b-modal id="category-modal" title="Vali kategooria" size="xl">
        <b-overlay
          :show="isModalLoading"
          opacity="0.3"
          variant="white"
          rounded="sm"
        >
          <b-row>
            <b-col>
              <h4>Kategooriad</h4>
              <TreeView
                :items="categories"
                @add="addModalCategoryToMap"
                :showAdd="true"
              />
            </b-col>
            <b-col>
              <h4>Kategooriead siin map-is</h4>
              <b-table-simple small bordered responiseve v-if="map.categories">
                <b-tr
                  v-for="category in map.categories"
                  :key="category.id"
                >
                  <b-td style="vertical-align: middle;">{{
                    category.full_name
                  }}</b-td>
                  <b-td class="text-center">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click.prevent="removeCategoryFromMap(category.id)"
                      >X</b-button
                    >
                  </b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>

      <div class="mt-1 mb-2">
        Vali kategooria
        <v-select
          v-model="selectedCategory"
          :options="flatCategories"
          :reduce="(item) => item.code"
        ></v-select>
      </div>
      <div class="mt-3 mb-2" v-if="selectedCategory">
        <b-button
          variant="primary"
          size="sm"
          @click.prevent="addFlatCategoryToMap(null)"
        >
          Lisa kategooria map-i
        </b-button>
      </div>

      <hr />

      <div class="mt-3 mb-2">
        <strong>Kategooriad siin map-is</strong>
      </div>

      <b-table-simple
        small
        bordered
        responiseve
        v-if="map.categories && map.categories.length"
      >
        <b-tr v-for="category in map.categories" :key="category.id">
          <b-td style="vertical-align: middle;">{{ category.full_name }}</b-td>
          <b-td class="text-center">
            <b-button
              variant="danger"
              size="sm"
              @click.prevent="removeFlatCategoryFromMap(category.id)"
              >X</b-button
            >
          </b-td>
        </b-tr>
      </b-table-simple>
      <b-alert v-else show variant="warning">
        Kategooriaid pole siin mappis.
      </b-alert>

      <hr />

      <div class="mt-3 mb-2">
        <strong>Soovitatavad kategooriad</strong>
        <b-button
          :disabled="suggestedIsLoading"
          class="ml-2"
          variant="primary"
          size="sm"
          @click.prevent="loadSuggestedCategories"
        >
          <b-spinner small v-if="suggestedIsLoading"></b-spinner>
          Lae
        </b-button>
      </div>

      <template v-if="suggestedCategories !== null">
        <b-table-simple
          small
          bordered
          responiseve
          v-if="suggestedCategories.length"
        >
          <b-tr v-for="category in suggestedCategories" :key="category.id">
            <b-td style="vertical-align: middle;">
              {{ category.full_name }}
            </b-td>
            <b-td class="text-center">
              <b-button
                variant="primary"
                size="sm"
                @click.prevent="addFlatCategoryToMap(category.id)"
              >
                +
              </b-button>
            </b-td>
          </b-tr>
        </b-table-simple>
        <b-alert v-else show variant="warning">
          Soovitatavad kategooriad puuduvad.
        </b-alert>
      </template>

      <hr />

      <div class="mt-3 mb-2">
        <strong>Tooted siin map-is</strong>
        <b-button
          :disabled="productsInMapIsLoading"
          class="ml-2"
          variant="primary"
          size="sm"
          @click.prevent="loadProductsInMap"
        >
          <b-spinner small v-if="productsInMapIsLoading"></b-spinner>
          Lae
        </b-button>
      </div>

      <template v-if="productsInMap !== null">
        <table
          class="table table-bordered table-sm"
          v-if="productsInMap.length"
        >
          <tr v-for="product in productsInMap" v-bind:key="product.id">
            <td style="width: 100px;">
              <img v-bind:src="product.main_image" alt="" class="img-fluid" />
            </td>
            <td>
              <router-link
                target="_blank"
                :to="{
                  name: 'products.edit',
                  params: { productId: product.id },
                }"
              >
                {{ product.name }}
              </router-link>
            </td>
          </tr>
        </table>

        <b-alert v-else show variant="warning">
          Tooteid ei ole siin mapis.
        </b-alert>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import TreeView from "../../../components/treeView/TreeView";
import { mapGetters, mapActions } from "vuex";
import RestService from "@/lib/RestService";

export default {
  components: {
    TreeView,
  },
  props: ["map"],
  data() {
    return {
      isModalLoading: false,
      isMapInfoLoading: false,
      selectedCategory: null,

      suggestedCategories: null,
      productsInMap: null,
      suggestedIsLoading: false,
      productsInMapIsLoading: false,
      deliveryPrice: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: "categories/categories",
    }),
    flatCategories() {
      let categories = [];

      if (this.categories) {
        categories = this.makeCategoryList(this.categories);
      }

      return categories;
    },
  },
  mounted() {
    if (this.map.deliveryPrice.length) {
      this.deliveryPrice = this.map.deliveryPrice[0].price
    } else {
      this.deliveryPrice = null;
    }
  },
  methods: {
    ...mapActions({
      addCategoryToMap: "categoryMapper/addCategoryToMap",
      setIgnoreCategoryMap: "categoryMapper/setIgnoreCategoryMap",
      removeCategoryFromMapState: "categoryMapper/removeCategoryFromMap",
      setCategoryMapDeliveryPrice: "categoryMapper/setCategoryMapDeliveryPrice",
    }),
    setCategoryMapIgnore(value) {
      this.setIgnoreCategoryMap({
        mapId: this.map.id,
        value: value,
      });
    },
    setCategoryDeliveryPrice() {
      this.setCategoryMapDeliveryPrice({
        mapId: this.map.id,
        price: this.deliveryPrice,
      });
    },
    addModalCategoryToMap(categoryId) {
      this.isModalLoading = true;

      this.addCategoryToMap({
        mapId: this.map.id,
        categoryId: categoryId,
      }).then(() => {
        this.isModalLoading = false;
      });
    },
    addFlatCategoryToMap(categoryId = null) {
      this.isMapInfoLoading = true;

      let id = categoryId;

      if (!categoryId) {
        id = this.selectedCategory;
      }

      this.addCategoryToMap({
        mapId: this.map.id,
        categoryId: id,
      }).then(() => {
        this.selectedCategory = null;
        this.isMapInfoLoading = false;
      });
    },
    removeCategoryFromMap(categoryId) {
      this.isModalLoading = true;

      this.removeCategoryFromMapState({
        mapId: this.map.id,
        categoryId: categoryId,
      }).then(() => {
        this.isModalLoading = false;
      });
    },
    removeFlatCategoryFromMap(categoryId) {
      this.isMapInfoLoading = true;

      this.removeCategoryFromMapState({
        mapId: this.map.id,
        categoryId: categoryId,
      }).then(() => {
        this.isMapInfoLoading = false;
      });
    },
    makeCategoryList(items, name = "") {
      let list = [];

      items.forEach((item) => {
        list.push({
          label: `${name}${item.name}`,
          code: item.id,
        });

        let childList = [];
        if (item.children.length) {
          childList = this.makeCategoryList(
            item.children,
            `${name}${item.name} > `
          );
        }

        list = list.concat(childList);
      });

      return list;
    },
    loadSuggestedCategories() {
      this.suggestedIsLoading = true;
      RestService.getCategoryMapSuggestedCategories(this.map.id).then(
        (response) => {
          this.suggestedCategories = response.data.data;
          this.suggestedIsLoading = false;
        }
      );
    },
    loadProductsInMap() {
      this.productsInMapIsLoading = true;
      RestService.getCategoryMapProducts(this.map.id).then((response) => {
        this.productsInMap = response.data.data;
        this.productsInMapIsLoading = false;
      });
    },
  },
  watch: {
    map: {
      deep: true,
      handler() {
        if (this.map.deliveryPrice.length) {
          this.deliveryPrice = this.map.deliveryPrice[0].price
        } else {
          this.deliveryPrice = null;
        }
      }
    }
  }
};
</script>
