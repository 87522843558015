<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Import logi
    </h1>

    <b-row class="mb-3">
      <b-col>
        <b-form-group label="Tarnija">
          <b-form-select
            v-if="suppliersSelect.length"
            v-model="selectedStock"
            :options="suppliersSelect"
            size="sm"
            @change="fetchImportLogs(1)"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table-simple responsive bordered small>
      <b-tr>
        <b-th>Supplier</b-th>
        <b-th>Run time</b-th>
        <b-th>Memory</b-th>
        <b-th>Inserted</b-th>
        <b-th>Updated</b-th>
        <b-th>Total</b-th>
        <b-th>Missing</b-th>
        <b-th>Logi</b-th>
        <b-th>Created at</b-th>
        <b-th>Updated at</b-th>
      </b-tr>
      <b-tr v-for="log in logs" :key="log.id">
        <b-td>
          {{ log.supplier.name }}
        </b-td>
        <b-td>
          {{ log.run_time }}
        </b-td>
        <b-td>
          {{ log.memory_used }}
        </b-td>
        <b-td>
          {{ log.products_inserted }}
        </b-td>
        <b-td>
          {{ log.products_updated }}
        </b-td>
        <b-td>
          {{ log.products_total }}
        </b-td>
        <b-td>
          {{ log.products_missing }}
        </b-td>
        <b-td>
          <LogModal :log="log" />
        </b-td>
        <b-td>
          {{ log.created_at | moment("DD.MM.YYYY HH:mm:ss") }}
        </b-td>
        <b-td>
          {{ log.updated_at | moment("DD.MM.YYYY HH:mm:ss") }}
        </b-td>
      </b-tr>
    </b-table-simple>

    <template v-if="pagination">
      <small>Logisid kokku: {{ pagination.total | number }}</small>
      <b-pagination
        class="mt-3"
        v-model="currentPage"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="pageChange"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import LogModal from "./LogModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LogModal,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      logs: null,
      pagination: null,
      selectedStock: null,
    };
  },
  computed: {
    ...mapGetters({
      suppliersSelect: "generic/suppliersSelect",
    }),
  },
  mounted() {
    this.fetchSuppliers();
    this.fetchImportLogs();
  },
  methods: {
    ...mapActions({
      fetchSuppliers: "generic/fetchSuppliers",
    }),
    fetchImportLogs(page = 1) {
      this.isLoading = true;
      RestService.getImportLogs({
        page: page,
        supplier_id: this.selectedStock,
      }).then((response) => {
        this.logs = response.data.data;
        this.pagination = response.data.meta.pagination;
        this.currentPage = this.pagination.current_page;
        this.isLoading = false;
      });
    },
    pageChange(page) {
      this.fetchImportLogs(page);
    },
  },
};
</script>
