<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Kliendi muutmine <small v-if="fullName">- {{ fullName }}</small>
    </h1>

    <template v-if="user">
      <b-row>
        <b-col md="6">
          <b-card header="Konto info">
            <b-form @submit.prevent="saveUser">
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Eesnimi">
                    <b-form-input
                      v-model="user.first_name"
                      size="sm"
                      :state="getFieldStateFromErrors('first_name')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ getFieldError("first_name") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1" label="Perenimi">
                    <b-form-input
                      v-model="user.last_name"
                      size="sm"
                      :state="getFieldStateFromErrors('last_name')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ getFieldError("last_name") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group class="mb-1" label="Email">
                    <b-form-input
                      v-model="user.email"
                      size="sm"
                      disabled
                      :state="getFieldStateFromErrors('email')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ getFieldError("email") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-1" label="Telefon">
                    <b-form-input
                      v-model="user.phone"
                      size="sm"
                      :state="getFieldStateFromErrors('phone')"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ getFieldError("phone") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-3">
                <b-col>
                  <b-form-radio-group size="sm" v-model="user.person_type">
                    <b-form-radio value="1">Eraisik</b-form-radio>
                    <b-form-radio value="2">Ettevõte</b-form-radio>
                    <b-form-radio value="3">Riigiasutus</b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
              <template v-if="user.person_type == 2 || user.person_type == 3">
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Ettevõtte nimi">
                      <b-form-input
                        v-model="user.company_name"
                        size="sm"
                        :state="getFieldStateFromErrors('company_name')"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ getFieldError("company_name") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-1" label="Ettevõtte reg nr">
                      <b-form-input
                        v-model="user.company_code"
                        size="sm"
                      :state="getFieldStateFromErrors('company_code')"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ getFieldError("company_code") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group class="mb-1" label="KMKR nr">
                      <b-form-input
                        v-model="user.company_vat"
                        size="sm"
                      :state="getFieldStateFromErrors('company_vat')"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ getFieldError("company_vat") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h6 class="mt-3 font-weight-bold">
                      Ettevõtte / Maksja aadress:
                    </h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Riik" v-if="countries">
                      <v-select
                        v-model="user.company_address.country"
                        :options="countries"
                        :reduce="(item) => item.code"
                        :clearable="false"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-1" label="Maakond">
                      <b-form-input
                        v-model="user.company_address.county"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Linn/Vald">
                      <b-form-input
                        v-model="user.company_address.city"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-1" label="Postiindeks">
                      <b-form-input
                        v-model="user.company_address.zip"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-1" label="Aadress">
                      <b-form-input
                        v-model="user.company_address.address"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-1 mt-4">
                      <b-form-checkbox
                        v-model="user.einvoice"
                        :value="true"
                        :unchecked-value="false"
                      >
                        E-arve
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>

              <hr>

              <b-row>
                <b-col>
                  <h6 class="mt-3 font-weight-bold">
                    Eeva andmed:
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="mb-1">
                    Ettemaksuga klient: 
                    <b-badge variant="success" v-if="user.is_pre_payment">Jah</b-badge>
                    <b-badge variant="danger" v-else>Ei</b-badge>
                  </div>
                  <div class="mb-1">
                    Maksetähtaeg: 
                    <span v-if="user.payment_days">{{ user.payment_days }}</span>
                    <b-badge variant="danger" v-else>Puudub</b-badge>
                  </div>
                  <div class="mb-1">
                    Hinnatase: 
                    <span v-if="user.discount_class">{{ user.discount_class }}</span>
                    <b-badge variant="danger" v-else>Puudub</b-badge>
                  </div>
                  <div class="mb-1">
                    <b-form-group class="mb-1" label="Eeva kood:">
                      <b-form-input
                        v-model="user.eeva_code"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col></b-col>
              </b-row>

              <div class="text-right mt-3">
                <b-button type="submit" variant="success" size="sm">
                  Salvesta
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-card header="Kasutaja tellimused">
            <template v-if="user.orders.length">
              <OrderItem
                v-for="order in user.orders"
                :order="order"
                :key="order.id"
              />
            </template>
            <b-alert v-else variant="warning" show>
              Kasutajal ei ole veel ühtegi tellimust.
            </b-alert>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import OrderItem from "@/app/orders/components/OrderItem";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [ErrorsMixin],
  components: {
    OrderItem,
  },
  data() {
    return {
      isLoading: false,
      user: null,
    };
  },
  computed: {
    ...mapGetters({
      countries: "generic/countries",
    }),
    fullName() {
      if (!this.user) {
        return null;
      }

      return `${this.user.first_name} ${this.user.last_name}`;
    },
  },
  mounted() {
    this.fetchCountries();
    this.fetchUser();
  },
  methods: {
    ...mapActions({
      fetchCountries: "generic/fetchCountries",
    }),
    fetchUser() {
      this.isLoading = true;
      RestService.getUser(this.$route.params.userId).then((response) => {
        this.clearValidationErrors();

        this.user = response.data.data;
        this.isLoading = false;
      });
    },
    saveUser() {
      this.isLoading = true;
      RestService.saveUser(this.$route.params.userId, this.user)
        .then((response) => {
          this.clearValidationErrors();
          this.user = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
  },
};
</script>
