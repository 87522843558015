<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h1>
      Veebi tõlked
    </h1>

    <b-form-group class="mb-3">
      <b-form-input
        class="form-control"
        v-model="keyword"
        size="sm"
        placeholder="Otsingu sõna"
      ></b-form-input>
    </b-form-group>

    <b-form-group class="mb-3">
      <b-form-checkbox v-model="showOnlyNoTranslated" :unchecked-value="false" :value="true" switch>
        Kuva ainult tõlkimata
      </b-form-checkbox>
    </b-form-group>

    <template v-if="filteredTranslations.length">
      <b-row v-for="translation in filteredTranslations" :key="translation.key">
        <b-col cols="12">
          <strong>
            {{ translation.key }}
          </strong>
        </b-col>
        <TranslationItem
          v-for="item in translation.item"
          :key="`${item.key}-${item.locale}`"
          :item="item"
        />
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-alert v-if="!isLoading" variant="warning" show>
        Tulemusi ei leitud
      </b-alert>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import { mapActions, mapGetters } from "vuex";
import TranslationItem from "./TranslationItem";

export default {
  components: {
    TranslationItem,
  },
  data() {
    return {
      isLoading: false,
      translations: [],
      keyword: null,
      showOnlyNoTranslated: false,
    };
  },
  computed: {
    ...mapGetters({}),
    filteredTranslations() {
      let translations = this.translations;

      if (this.showOnlyNoTranslated) {
        translations = translations.filter((t) => {
          let notTranslated = false;
          t.item.forEach(element => {
            if (!element.value) {
              return notTranslated = true;
            }
          });

          return notTranslated;
        })
      }

      if (this.keyword) {
        return translations.filter((translation) => {
          if (
            translation.key.toLowerCase().indexOf(this.keyword.toLowerCase()) !=
            -1
          ) {
            return true;
          }

          return false;
        });
      }

      return translations;
    },
  },
  mounted() {
    this.fetchTranslations();
  },
  methods: {
    ...mapActions({}),
    fetchTranslations() {
      this.isLoading = true;
      RestService.getStringTranslations().then((response) => {
        this.translations = response.data.data;
        this.isLoading = false;
      });
    },
  },
};
</script>
